<!-- 
    页面 黑白名单详情-设备 (这里的Table 用了 elemnt-ui)
-->
<template>
    <div id="black-white-detail-page" class="data-list-container dark-layout">
        <vx-card  class="mb-5">
          <!-- 主版页-->
          <el-descriptions title="设备基础信息">
              <el-descriptions-item label="设备名称">{{deviceInfo.device_name}}</el-descriptions-item>
              <el-descriptions-item label="设备类型">{{deviceInfo.product_model}}</el-descriptions-item>
              <el-descriptions-item label="设备编号">{{deviceInfo.device_code}}</el-descriptions-item>
              <el-descriptions-item label="点位名称">{{deviceInfo.space_name}}</el-descriptions-item>
              <el-descriptions-item label="状态">
                 <template>
                    <el-tag size="mini" v-if="deviceInfo.run_mode == 0" type="warning">离线</el-tag>
                    <el-tag size="mini" v-if="deviceInfo.run_mode == 1" type="success">在线</el-tag>
                    <el-tag size="mini" v-if="deviceInfo.run_mode == 2" type="warning">故障</el-tag>
                    <el-tag size="mini" v-if="deviceInfo.run_mode == 3" type="danger">维保</el-tag>
                  </template>
              </el-descriptions-item>
          </el-descriptions>
          <el-descriptions :column="1">
              <el-descriptions-item label="下发状态">{{deviceInfo.下发总状态}}</el-descriptions-item>
              <el-descriptions-item label="下发详情">{{deviceInfo.下发详情}}</el-descriptions-item>
          </el-descriptions>
        </vx-card>

        <vx-card>
            <div class="table_height_container">
                <!-- 操作按钮 -->
                <el-row class="search-row mb-5" type="flex">
                  <el-col :span="4">
                    <el-radio-group v-model="isBlackOrWhite" size="mini" @change="isBlackOrWhiteChange">
                      <el-radio-button label="黑名单"></el-radio-button>
                      <el-radio-button label="白名单"></el-radio-button>
                    </el-radio-group>
                  </el-col>
                  <!-- 操作按钮 -->
                  <el-col :span="20" style="line-height: 2">
                    <div style="float:right">
                      <el-input class="search-input" size="mini" v-model="selectOption.card_no" placeholder="请输入卡号"></el-input>
                      <el-input class="search-input" size="mini" v-model="selectOption.people_name" placeholder="请输入姓名"></el-input>
                      <el-input class="search-input" size="mini" v-model="selectOption.certificate_no" placeholder="请输入身份证"></el-input>
                      <el-button class="search-btn" size="mini"  @click="searchAction">搜索</el-button>
                    </div>
                    <el-dropdown  @command="changeSyncStatus" style="float:right; margin-right:10px">
                      <span class="el-dropdown-link">
                        <span class="inline-block mr-2" style="font-weight: bold; font-size: 12px;">下发状态:</span>
                        <el-tag size="mini" v-if="sync_status == 1" type="success">成功</el-tag>
                        <el-tag size="mini" v-if="sync_status == 2" type="danger">失败</el-tag>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">成功</el-dropdown-item>
                        <el-dropdown-item command="2">失败</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </el-col>
                </el-row>
                <!-- 整体表 -->
                <el-table class="table_theme" :data="blackwhitelist" ref="rolesTable" row-key="device_id" v-loading="loading">
                    <el-table-column type="index" label="序号">
                        <template slot-scope="scope">
                            <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="card_no" label="卡号" show-overflow-tooltip></el-table-column>
                    <el-table-column property="people_name" label="卡所属人姓名"  show-overflow-tooltip></el-table-column>
                    <el-table-column property="certificate_no" label="卡所属人身份证"  show-overflow-tooltip></el-table-column>
                    <el-table-column property="down_state" label="下发状态"  show-overflow-tooltip>
                        <template>
                          <el-tag size="mini" v-if="sync_status == 1" type="success">成功</el-tag>
                          <el-tag size="mini" v-if="sync_status == 2" type="danger">失败</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="bottom mt-5 text-center">
                <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageOpt.currentPage"
                    :page-sizes="pageOpt.pageSizes"
                    :page-size="pageOpt.currentPageSize"
                    :pager-count="pageOpt.pageCount"
                    :total="pageOpt.totalPageSize"
                    :hide-on-single-page="pageOpt.hideSinglePage"
                ></el-pagination>
                </div>
            </div>
        </vx-card>

  </div>
</template>

<script>
import { Base64 } from 'js-base64'
export default {
  data() {
    return {
      // 黑白名单切换
      isBlackOrWhite: "黑名单",
      sync_status: "1",
      // 筛选状态
      selectOption: {
        village_code: this.$store.state.auth.space.space_code || "",
        certificate_no:"",
        people_name: "",
        card_no: "",
        // index: 1,
        // pagesize: 10
      },
      // 渲染数据
      deviceInfo: {
        device_code: "",
        device_name: "",
        device_type: "",
        product_model: "",
        run_mode: "",
        space_name: "",
        下发总状态: "",
        下发详情: ""
      },
      blackwhitelist: [],
      // 加载状态
      height: this.$store.state.windowHeight,
      currentIndex: null,
      drawer: false,
      loading: false,
      drawerLoading: false,
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  watch: {
    "$store.state.auth.space.space_code"(val) {
      this.$router.push({ name : 'blackAndwhiteList'})
    },
  },
  methods: {
    // 状态切换
    changeSyncStatus(val) {
      this.sync_status = val
      this.pageOpt.currentPage = 1;
      this.getBlackWhiteList(this.sync_status)
      this.getBlackWhiteCount(this.sync_status)
    },
    // 黑白名单切换 
    isBlackOrWhiteChange() {
      this.pageOpt.currentPage = 1;
      this.getBlackWhiteList(this.sync_status)
      this.getBlackWhiteCount(this.sync_status)
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh =
        rowIndex +
        1 +
        (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize;
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getBlackWhiteList(this.sync_status)
      this.getBlackWhiteCount(this.sync_status)
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getBlackWhiteList(this.sync_status)
      this.getBlackWhiteCount(this.sync_status)
    },
    // 查看详情
    checkInfo(deviceID) {
      this.$router.push({ name : 'blackAndwhiteDetail', params: { deviceID }})
    },
    // 获取单个设备黑白名单数量 
    // sync_status  下发状态 1 成功 2 失败
    async getBlackWhiteCount(sync_status) {
      try {
        let params = {
          "device_code": this.deviceInfo.device_code,
          "sync_type": this.isBlackOrWhite === "黑名单" ? "1" : "3",
          "sync_status": sync_status,
          "card_no": this.selectOption.card_no,
          "people_name": this.selectOption.people_name,
          "certificate_no": this.selectOption.certificate_no
        }
        let result = await this.$apis.ODC("获取单个设备黑白名单数量", params);
        if (this.$apis.isOk(result)) {
          this.pageOpt.totalPageSize = result.blackwhitecnt;
        } else {
           this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 获取单个设备黑白名单列表
    async getBlackWhiteList(sync_status) {
      try {
        let params = {
          "device_code": this.deviceInfo.device_code,
          "sync_type": this.isBlackOrWhite === "黑名单" ? 1 : 3,
          "sync_status": sync_status,
          "card_no": this.selectOption.card_no,
          "people_name": this.selectOption.people_name,
          "certificate_no": this.selectOption.certificate_no,
          "index": this.pageOpt.currentPage,
          "pagesize":  this.pageOpt.currentPageSize
        }
        let result = await this.$apis.ODC("获取单个设备黑白名单列表", params);
        if (this.$apis.isOk(result)) {
          this.blackwhitelist = result.blackwhitelist
        } else {
           this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
         this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 请求接口
    async searchAction(){
      this.pageOpt.currentPage = 1;
      this.getBlackWhiteList(this.sync_status)
      this.getBlackWhiteCount(this.sync_status)
    },
  },
  async created() {
    // 请求数据
    try {
      this.selectOption.card_no = this.$route.query.card_no
      this.deviceInfo = JSON.parse(Base64.decode(this.$route.query.deviceInfo))
    } catch (e) {
      this.$router.push({ name : 'blackAndwhiteList'})
    }
    this.getBlackWhiteList(this.sync_status)
    this.getBlackWhiteCount(this.sync_status)
  },
  components: {
  }
};
</script>

<style lang="scss" scope="scope">
  .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    box-shadow: 0 0 0px 0px transparent !important
  }
  #black-white-detail-page {
    .search-row {
      margin-bottom: 5px;
      .search-select {
        margin-right: 5px;
      }
      .search-input {
        // float: right;
        width: 150px;
        margin-right: 5px;
      }
      .search-btn{
        // float: right;
        &:hover {
          background-color: rgba(var(--vs-primary), 0.1);
          border-color: rgba(var(--vs-primary), 0.1);
        }
      }
    }
    .el-radio-button__orig-radio:checked+.el-radio-button__inner{
      background-color: rgba(var(--vs-primary), 1);
      border-color:  rgba(var(--vs-primary), 0.1);
      box-shadow: -1px 0 0 0 rgba(var(--vs-primary), 0.1);;
    }
    .el-radio-button__inner:hover {
      color: rgba(98,98,98, 0.5);
    }
    .table_theme {
      .el-table__expanded-cell[class*=cell] {
        padding: 0px;
      }
      .el-table__body tr{
        &:hover {
          cursor: pointer;
        }
      }
    }
    .table_height_container {
      display: flex;
      flex-direction: column;
      min-height: calc(100vh - 400px);
    }
    .el-button.is-disabled.el-button--text {
      text-decoration: line-through;
    }
    .cardListDrawer {
      top: 60px;
      .el-drawer__header {
        margin-bottom: 0px;
      }
      .box-card {
        margin-bottom: 10px;
        .item {
          position: relative;
          height: 100px;
          .bottom {
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
</style>